import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './cons-beneficiary-document-type.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ConsBeneficiaryDocumentTypeDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const consBeneficiaryDocumentTypeEntity = useAppSelector(state => state.consBeneficiaryDocumentType.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="consBeneficiaryDocumentTypeDetailsHeading">
          <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.detail.title">ConsBeneficiaryDocumentType</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.code">Code</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.name">Name</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.name}</dd>

          <dt>
            <span id="nameAr">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.nameAr">Name Ar</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.nameAr}</dd>

          <dt>
            <span id="nameEn">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.nameEn">Name En</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.nameEn}</dd>

          <dt>
            <span id="folderName">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.folderName">Folder Name</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.folderName}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="almobadaraMasterDataApp.consBeneficiaryDocumentType.status">Status</Translate>
            </span>
          </dt>
          <dd>{consBeneficiaryDocumentTypeEntity.status}</dd>
        </dl>
        <Button tag={Link} to="/cons-beneficiary-document-type" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/cons-beneficiary-document-type/${consBeneficiaryDocumentTypeEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ConsBeneficiaryDocumentTypeDetail;
