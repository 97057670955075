import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetTypeDonorMoral from './met-type-donor-moral';
import MetTypeDonorMoralDetail from './met-type-donor-moral-detail';
import MetTypeDonorMoralUpdate from './met-type-donor-moral-update';
import MetTypeDonorMoralDeleteDialog from './met-type-donor-moral-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetTypeDonorMoralUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetTypeDonorMoralUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetTypeDonorMoralDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetTypeDonorMoral} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetTypeDonorMoralDeleteDialog} />
  </>
);

export default Routes;
