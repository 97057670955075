import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsEducationDocumentType from './cons-education-document-type';
import ConsEducationDocumentTypeDetail from './cons-education-document-type-detail';
import ConsEducationDocumentTypeUpdate from './cons-education-document-type-update';
import ConsEducationDocumentTypeDeleteDialog from './cons-education-document-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsEducationDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsEducationDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsEducationDocumentTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsEducationDocumentType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsEducationDocumentTypeDeleteDialog} />
  </>
);

export default Routes;
