import { IMetCategory } from 'app/shared/model/met-category.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IParStatus {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status;
  categories?: IMetCategory[] | null;
}

export const defaultValue: Readonly<IParStatus> = {};
