import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetHandicapType from './met-handicap-type';
import MetHandicapTypeDetail from './met-handicap-type-detail';
import MetHandicapTypeUpdate from './met-handicap-type-update';
import MetHandicapTypeDeleteDialog from './met-handicap-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetHandicapTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetHandicapTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetHandicapTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetHandicapType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetHandicapTypeDeleteDialog} />
  </>
);

export default Routes;
