import { IConsTypeProductNature } from 'app/shared/model/cons-type-product-nature.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IMetProductNature {
  id?: number;
  code?: string;
  name?: string;
  status?: Status;
  nameAr?: string;
  nameEn?: string;
  typeProductNature?: IConsTypeProductNature | null;
}

export const defaultValue: Readonly<IMetProductNature> = {};
