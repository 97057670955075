import { Status } from 'app/shared/model/enumerations/status.model';

export interface IMetDiseases {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status;
}

export const defaultValue: Readonly<IMetDiseases> = {};
