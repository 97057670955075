import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsMemberDocumentType from './cons-member-document-type';
import ConsMemberDocumentTypeDetail from './cons-member-document-type-detail';
import ConsMemberDocumentTypeUpdate from './cons-member-document-type-update';
import ConsMemberDocumentTypeDeleteDialog from './cons-member-document-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsMemberDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsMemberDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsMemberDocumentTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsMemberDocumentType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsMemberDocumentTypeDeleteDialog} />
  </>
);

export default Routes;
