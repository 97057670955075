import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetActivitySector from './met-activity-sector';
import MetActivitySectorDetail from './met-activity-sector-detail';
import MetActivitySectorUpdate from './met-activity-sector-update';
import MetActivitySectorDeleteDialog from './met-activity-sector-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetActivitySectorUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetActivitySectorUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetActivitySectorDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetActivitySector} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetActivitySectorDeleteDialog} />
  </>
);

export default Routes;
