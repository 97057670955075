import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParSchoolYear from './par-school-year';
import ParSchoolYearDetail from './par-school-year-detail';
import ParSchoolYearUpdate from './par-school-year-update';
import ParSchoolYearDeleteDialog from './par-school-year-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ParSchoolYearUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ParSchoolYearUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ParSchoolYearDetail} />
      <ErrorBoundaryRoute path={match.url} component={ParSchoolYear} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ParSchoolYearDeleteDialog} />
  </>
);

export default Routes;
