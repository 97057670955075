import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetDiseaseTreatmentType from './met-disease-treatment-type';
import MetDiseaseTreatmentTypeDetail from './met-disease-treatment-type-detail';
import MetDiseaseTreatmentTypeUpdate from './met-disease-treatment-type-update';
import MetDiseaseTreatmentTypeDeleteDialog from './met-disease-treatment-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetDiseaseTreatmentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetDiseaseTreatmentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetDiseaseTreatmentTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetDiseaseTreatmentType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetDiseaseTreatmentTypeDeleteDialog} />
  </>
);

export default Routes;
