import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParTypeIdentity from './par-type-identity';
import ParTypeIdentityDetail from './par-type-identity-detail';
import ParTypeIdentityUpdate from './par-type-identity-update';
import ParTypeIdentityDeleteDialog from './par-type-identity-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ParTypeIdentityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ParTypeIdentityUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ParTypeIdentityDetail} />
      <ErrorBoundaryRoute path={match.url} component={ParTypeIdentity} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ParTypeIdentityDeleteDialog} />
  </>
);

export default Routes;
