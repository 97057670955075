import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsFamilyDocumentType from './cons-family-document-type';
import ConsFamilyDocumentTypeDetail from './cons-family-document-type-detail';
import ConsFamilyDocumentTypeUpdate from './cons-family-document-type-update';
import ConsFamilyDocumentTypeDeleteDialog from './cons-family-document-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsFamilyDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsFamilyDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsFamilyDocumentTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsFamilyDocumentType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsFamilyDocumentTypeDeleteDialog} />
  </>
);

export default Routes;
