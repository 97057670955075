import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useAppDispatch, useAppSelector } from 'app/config/store';
import { getEntity, deleteEntity } from './par-accommodation-type.reducer'; // Assurez-vous que le chemin est correct

export const ParAccommodationTypeDeleteDialog = (props: RouteComponentProps<{ id: string }>) => {
  const [loadModal, setLoadModal] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
    setLoadModal(true);
  }, [dispatch, props.match.params.id]);

  const parAccommodationTypeEntity = useAppSelector(state => state.parAccommodationType.entity);
  const updateSuccess = useAppSelector(state => state.parAccommodationType.updateSuccess);

  const handleClose = () => {
    props.history.push('/par-accommodation-type');
  };

  useEffect(() => {
    if (updateSuccess && loadModal) {
      handleClose();
      setLoadModal(false);
    }
  }, [updateSuccess, loadModal, handleClose]);

  const confirmDelete = () => {
    dispatch(deleteEntity(parAccommodationTypeEntity.id));
  };

  return (
    <Modal isOpen toggle={handleClose}>
      <ModalHeader toggle={handleClose} data-cy="parAccommodationTypeDeleteDialogHeading">
        <Translate contentKey="entity.delete.title">Confirm delete operation</Translate>
      </ModalHeader>
      <ModalBody id="almobadaraMasterDataApp.parAccommodationType.delete.question">
        <Translate
          contentKey="almobadaraMasterDataApp.parAccommodationType.delete.question"
          interpolate={{ id: parAccommodationTypeEntity.id }}
        >
          Are you sure you want to delete this ParAccommodationType?
        </Translate>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={handleClose}>
          <FontAwesomeIcon icon="ban" />
          &nbsp;
          <Translate contentKey="entity.action.cancel">Cancel</Translate>
        </Button>
        <Button id="jhi-confirm-delete-parAccommodationType" data-cy="entityConfirmDeleteButton" color="danger" onClick={confirmDelete}>
          <FontAwesomeIcon icon="trash" />
          &nbsp;
          <Translate contentKey="entity.action.delete">Archive</Translate>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ParAccommodationTypeDeleteDialog;
