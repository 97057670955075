import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetDonorContactFunction from './met-donor-contact-function';
import MetDonorContactFunctionDetail from './met-donor-contact-function-detail';
import MetDonorContactFunctionUpdate from './met-donor-contact-function-update';
import MetDonorContactFunctionDeleteDialog from './met-donor-contact-function-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetDonorContactFunctionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetDonorContactFunctionUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetDonorContactFunctionDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetDonorContactFunction} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetDonorContactFunctionDeleteDialog} />
  </>
);

export default Routes;
