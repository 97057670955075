import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetScholarship from './met-scholarship';
import MetScholarshipDetail from './met-scholarship-detail';
import MetScholarshipUpdate from './met-scholarship-update';
import MetScholarshipDeleteDialog from './met-scholarship-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetScholarshipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetScholarshipUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetScholarshipDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetScholarship} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetScholarshipDeleteDialog} />
  </>
);

export default Routes;
