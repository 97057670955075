import React from 'react';
import { Switch } from 'react-router-dom';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParCountry from './par-country';
import ParRegion from './par-region';
import ParCity from './par-city';
import MetProductNature from './met-product-nature';
import ConsTypeProductNature from './cons-type-product-nature';
import ParProductUnit from './par-product-unit';
import MetCanalDonation from './met-canal-donation';
import ConsTypeDonationDocument from './cons-type-donation-document';
import ParDonorStatus from './par-donor-status';
import ConsTypeDonorDocument from './cons-type-donor-document';
import MetActivitySector from './met-activity-sector';
import MetDonorContactFunction from './met-donor-contact-function';
import MetCanalCommunication from './met-canal-communication';
import ParLanguageCommunication from './par-language-communication';
import ParTypeIdentity from './par-type-identity';
import MetProfession from './met-profession';
import ParStatus from './par-status';
import MetCategory from './met-category';
import MetService from './met-service';
import ConsCardType from './cons-card-type';
import MetHealthInsurance from './met-health-insurance';
import MetOperationService from './met-operation-service';
import MetDiseases from './met-diseases';
import MetAllergies from './met-allergies';
import MetHandicapType from './met-handicap-type';
import ConsBeneficiaryDocumentType from './cons-beneficiary-document-type';
import MetDiseaseTreatmentType from './met-disease-treatment-type';
import MetEps from './met-eps';
import MetScholarship from './met-scholarship';
import ParSchoolLevel from './par-school-level';
import ParSchoolYear from './par-school-year';
import ParHonor from './par-honor';
import ParMajor from './par-major';
import MetIncomeSource from './met-income-source';
import ConsEducationDocumentType from './cons-education-document-type';
import ConsMemberDocumentType from './cons-member-document-type';
import MetFamilyRelationship from './met-family-relationship';
import ConsFamilyDocumentType from './cons-family-document-type';
import ConsTakeInChargeDocumentType from './cons-take-in-charge-document-type';
import MetTypeDonorMoral from './met-type-donor-moral';
import ParCurrency from './par-currency';
import ActionStatus from './action-status';
import ParAccommodationType from 'app/entities/par-accommodation-type';
import ParTypeKafalat from 'app/entities/par-type-kafalat';
import ParTypePriseEnCharge from 'app/entities/par-type-prise-en-charge';
/* jhipster-needle-add-route-import - JHipster will add routes here */

const Routes = ({ match }) => (
  <div>
    <Switch>
      {/* prettier-ignore */}
      <ErrorBoundaryRoute path={`${match.url}par-country`} component={ParCountry} />
      <ErrorBoundaryRoute path={`${match.url}par-region`} component={ParRegion} />
      <ErrorBoundaryRoute path={`${match.url}par-city`} component={ParCity} />
      <ErrorBoundaryRoute path={`${match.url}met-product-nature`} component={MetProductNature} />
      <ErrorBoundaryRoute path={`${match.url}cons-type-product-nature`} component={ConsTypeProductNature} />
      <ErrorBoundaryRoute path={`${match.url}par-product-unit`} component={ParProductUnit} />
      <ErrorBoundaryRoute path={`${match.url}met-canal-donation`} component={MetCanalDonation} />
      <ErrorBoundaryRoute path={`${match.url}cons-type-donation-document`} component={ConsTypeDonationDocument} />
      <ErrorBoundaryRoute path={`${match.url}par-donor-status`} component={ParDonorStatus} />
      <ErrorBoundaryRoute path={`${match.url}cons-type-donor-document`} component={ConsTypeDonorDocument} />
      <ErrorBoundaryRoute path={`${match.url}met-activity-sector`} component={MetActivitySector} />
      <ErrorBoundaryRoute path={`${match.url}met-donor-contact-function`} component={MetDonorContactFunction} />
      <ErrorBoundaryRoute path={`${match.url}met-canal-communication`} component={MetCanalCommunication} />
      <ErrorBoundaryRoute path={`${match.url}par-language-communication`} component={ParLanguageCommunication} />
      <ErrorBoundaryRoute path={`${match.url}par-type-identity`} component={ParTypeIdentity} />
      <ErrorBoundaryRoute path={`${match.url}met-profession`} component={MetProfession} />
      <ErrorBoundaryRoute path={`${match.url}par-status`} component={ParStatus} />
      <ErrorBoundaryRoute path={`${match.url}met-category`} component={MetCategory} />
      <ErrorBoundaryRoute path={`${match.url}met-service`} component={MetService} />
      <ErrorBoundaryRoute path={`${match.url}cons-card-type`} component={ConsCardType} />
      <ErrorBoundaryRoute path={`${match.url}met-health-insurance`} component={MetHealthInsurance} />
      <ErrorBoundaryRoute path={`${match.url}met-operation-service`} component={MetOperationService} />
      <ErrorBoundaryRoute path={`${match.url}met-diseases`} component={MetDiseases} />
      <ErrorBoundaryRoute path={`${match.url}met-allergies`} component={MetAllergies} />
      <ErrorBoundaryRoute path={`${match.url}met-handicap-type`} component={MetHandicapType} />
      <ErrorBoundaryRoute path={`${match.url}cons-beneficiary-document-type`} component={ConsBeneficiaryDocumentType} />
      <ErrorBoundaryRoute path={`${match.url}met-disease-treatment-type`} component={MetDiseaseTreatmentType} />
      <ErrorBoundaryRoute path={`${match.url}met-eps`} component={MetEps} />
      <ErrorBoundaryRoute path={`${match.url}met-scholarship`} component={MetScholarship} />
      <ErrorBoundaryRoute path={`${match.url}par-school-level`} component={ParSchoolLevel} />
      <ErrorBoundaryRoute path={`${match.url}par-school-year`} component={ParSchoolYear} />
      <ErrorBoundaryRoute path={`${match.url}par-honor`} component={ParHonor} />
      <ErrorBoundaryRoute path={`${match.url}par-major`} component={ParMajor} />
      <ErrorBoundaryRoute path={`${match.url}met-income-source`} component={MetIncomeSource} />
      <ErrorBoundaryRoute path={`${match.url}cons-education-document-type`} component={ConsEducationDocumentType} />
      <ErrorBoundaryRoute path={`${match.url}cons-member-document-type`} component={ConsMemberDocumentType} />
      <ErrorBoundaryRoute path={`${match.url}met-family-relationship`} component={MetFamilyRelationship} />
      <ErrorBoundaryRoute path={`${match.url}cons-family-document-type`} component={ConsFamilyDocumentType} />
      <ErrorBoundaryRoute path={`${match.url}cons-take-in-charge-document-type`} component={ConsTakeInChargeDocumentType} />
      <ErrorBoundaryRoute path={`${match.url}met-type-donor-moral`} component={MetTypeDonorMoral} />
      <ErrorBoundaryRoute path={`${match.url}par-currency`} component={ParCurrency} />
      <ErrorBoundaryRoute path={`${match.url}action-status`} component={ActionStatus} />
      <ErrorBoundaryRoute path={`${match.url}par-accommodation-type`} component={ParAccommodationType} />
      <ErrorBoundaryRoute path={`${match.url}par-type-kafalat`} component={ParTypeKafalat} />
      <ErrorBoundaryRoute path={`${match.url}par-type-prise-en-charge`} component={ParTypePriseEnCharge} />

      {/* jhipster-needle-add-route-path - JHipster will add routes here */}
    </Switch>
  </div>
);

export default Routes;
