import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParAccommodationType from './par-accommodation-type';
import ParAccommodationTypeDetail from './par-accommodation-type-detail';
import ParAccommodationTypeUpdate from './par-accommodation-type-update';
import ParAccommodationTypeDeleteDialog from './par-accommodation-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ParAccommodationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ParAccommodationTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ParAccommodationTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ParAccommodationType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ParAccommodationTypeDeleteDialog} />
  </>
);

export default Routes;
