import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetIncomeSource from './met-income-source';
import MetIncomeSourceDetail from './met-income-source-detail';
import MetIncomeSourceUpdate from './met-income-source-update';
import MetIncomeSourceDeleteDialog from './met-income-source-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetIncomeSourceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetIncomeSourceUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetIncomeSourceDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetIncomeSource} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetIncomeSourceDeleteDialog} />
  </>
);

export default Routes;
