import { Status } from 'app/shared/model/enumerations/status.model';

export interface IParMajor {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status | null;
}

export const defaultValue: Readonly<IParMajor> = {};
