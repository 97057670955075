import { IParCountry } from 'app/shared/model/par-country.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IParRegion {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status;
  country?: IParCountry | null;
}

export const defaultValue: Readonly<IParRegion> = {};
