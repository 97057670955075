import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetAllergies from './met-allergies';
import MetAllergiesDetail from './met-allergies-detail';
import MetAllergiesUpdate from './met-allergies-update';
import MetAllergiesDeleteDialog from './met-allergies-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetAllergiesUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetAllergiesUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetAllergiesDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetAllergies} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetAllergiesDeleteDialog} />
  </>
);

export default Routes;
