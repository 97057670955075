import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsTypeProductNature from './cons-type-product-nature';
import ConsTypeProductNatureDetail from './cons-type-product-nature-detail';
import ConsTypeProductNatureUpdate from './cons-type-product-nature-update';
import ConsTypeProductNatureDeleteDialog from './cons-type-product-nature-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsTypeProductNatureUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsTypeProductNatureUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsTypeProductNatureDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsTypeProductNature} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsTypeProductNatureDeleteDialog} />
  </>
);

export default Routes;
