import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetDiseases from './met-diseases';
import MetDiseasesDetail from './met-diseases-detail';
import MetDiseasesUpdate from './met-diseases-update';
import MetDiseasesDeleteDialog from './met-diseases-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetDiseasesUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetDiseasesUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetDiseasesDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetDiseases} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetDiseasesDeleteDialog} />
  </>
);

export default Routes;
