import React from 'react';
import MenuItem from 'app/shared/layout/menus/menu-item';
import { Translate, translate } from 'react-jhipster';
import { NavDropdown } from './menu-components';

export const EntitiesMenu = props => (
  <NavDropdown
    icon="th-list"
    name={translate('global.menu.entities.main')}
    id="entity-menu"
    data-cy="entity"
    style={{ maxHeight: '80vh', overflow: 'auto' }}
  >
    <>{/* to avoid warnings when empty */}</>
    <MenuItem icon="asterisk" to="/par-country">
      <Translate contentKey="global.menu.entities.parCountry" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-region">
      <Translate contentKey="global.menu.entities.parRegion" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-city">
      <Translate contentKey="global.menu.entities.parCity" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-product-nature">
      <Translate contentKey="global.menu.entities.metProductNature" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-type-product-nature">
      <Translate contentKey="global.menu.entities.consTypeProductNature" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-product-unit">
      <Translate contentKey="global.menu.entities.parProductUnit" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-canal-donation">
      <Translate contentKey="global.menu.entities.metCanalDonation" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-type-donation-document">
      <Translate contentKey="global.menu.entities.consTypeDonationDocument" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-donor-status">
      <Translate contentKey="global.menu.entities.parDonorStatus" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-type-donor-document">
      <Translate contentKey="global.menu.entities.consTypeDonorDocument" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-activity-sector">
      <Translate contentKey="global.menu.entities.metActivitySector" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-donor-contact-function">
      <Translate contentKey="global.menu.entities.metDonorContactFunction" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-canal-communication">
      <Translate contentKey="global.menu.entities.metCanalCommunication" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-language-communication">
      <Translate contentKey="global.menu.entities.parLanguageCommunication" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-type-identity">
      <Translate contentKey="global.menu.entities.parTypeIdentity" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-profession">
      <Translate contentKey="global.menu.entities.metProfession" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-status">
      <Translate contentKey="global.menu.entities.parStatus" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-category">
      <Translate contentKey="global.menu.entities.metCategory" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-service">
      <Translate contentKey="global.menu.entities.metService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-card-type">
      <Translate contentKey="global.menu.entities.consCardType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-health-insurance">
      <Translate contentKey="global.menu.entities.metHealthInsurance" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-operation-service">
      <Translate contentKey="global.menu.entities.metOperationService" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-diseases">
      <Translate contentKey="global.menu.entities.metDiseases" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-allergies">
      <Translate contentKey="global.menu.entities.metAllergies" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-handicap-type">
      <Translate contentKey="global.menu.entities.metHandicapType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-beneficiary-document-type">
      <Translate contentKey="global.menu.entities.consBeneficiaryDocumentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-disease-treatment-type">
      <Translate contentKey="global.menu.entities.metDiseaseTreatmentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-eps">
      <Translate contentKey="global.menu.entities.metEps" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-scholarship">
      <Translate contentKey="global.menu.entities.metScholarship" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-school-level">
      <Translate contentKey="global.menu.entities.parSchoolLevel" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-school-year">
      <Translate contentKey="global.menu.entities.parSchoolYear" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-honor">
      <Translate contentKey="global.menu.entities.parHonor" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-major">
      <Translate contentKey="global.menu.entities.parMajor" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-income-source">
      <Translate contentKey="global.menu.entities.metIncomeSource" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-education-document-type">
      <Translate contentKey="global.menu.entities.consEducationDocumentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-member-document-type">
      <Translate contentKey="global.menu.entities.consMemberDocumentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-family-relationship">
      <Translate contentKey="global.menu.entities.metFamilyRelationship" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-family-document-type">
      <Translate contentKey="global.menu.entities.consFamilyDocumentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/cons-take-in-charge-document-type">
      <Translate contentKey="global.menu.entities.consTakeInChargeDocumentType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/met-type-donor-moral">
      <Translate contentKey="global.menu.entities.metTypeDonorMoral" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-currency">
      <Translate contentKey="global.menu.entities.parCurrency" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/action-status">
      <Translate contentKey="global.menu.entities.actionStatus" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-accommodation-type">
      <Translate contentKey="global.menu.entities.parAccommodationType" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-type-kafalat">
      <Translate contentKey="global.menu.entities.parTypeKafalat" />
    </MenuItem>
    <MenuItem icon="asterisk" to="/par-type-prise-en-charge">
      <Translate contentKey="global.menu.entities.parTypePriseEnCharge" />
    </MenuItem>
    {/* jhipster-needle-add-entity-to-menu - JHipster will add entities to the menu here */}
  </NavDropdown>
);
