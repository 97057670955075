import { IParRegion } from 'app/shared/model/par-region.model';
import { Status } from 'app/shared/model/enumerations/status.model';

export interface IParCity {
  id?: number;
  code?: string;
  name?: string;
  nameAr?: string;
  nameEn?: string;
  status?: Status;
  region?: IParRegion | null;
}

export const defaultValue: Readonly<IParCity> = {};
