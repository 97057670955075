import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import MetProductNature from './met-product-nature';
import MetProductNatureDetail from './met-product-nature-detail';
import MetProductNatureUpdate from './met-product-nature-update';
import MetProductNatureDeleteDialog from './met-product-nature-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={MetProductNatureUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={MetProductNatureUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={MetProductNatureDetail} />
      <ErrorBoundaryRoute path={match.url} component={MetProductNature} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={MetProductNatureDeleteDialog} />
  </>
);

export default Routes;
