import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsCardType from './cons-card-type';
import ConsCardTypeDetail from './cons-card-type-detail';
import ConsCardTypeUpdate from './cons-card-type-update';
import ConsCardTypeDeleteDialog from './cons-card-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsCardTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsCardTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsCardTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsCardType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsCardTypeDeleteDialog} />
  </>
);

export default Routes;
