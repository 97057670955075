import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ConsBeneficiaryDocumentType from './cons-beneficiary-document-type';
import ConsBeneficiaryDocumentTypeDetail from './cons-beneficiary-document-type-detail';
import ConsBeneficiaryDocumentTypeUpdate from './cons-beneficiary-document-type-update';
import ConsBeneficiaryDocumentTypeDeleteDialog from './cons-beneficiary-document-type-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ConsBeneficiaryDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ConsBeneficiaryDocumentTypeUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ConsBeneficiaryDocumentTypeDetail} />
      <ErrorBoundaryRoute path={match.url} component={ConsBeneficiaryDocumentType} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ConsBeneficiaryDocumentTypeDeleteDialog} />
  </>
);

export default Routes;
