import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParTypeKafalat from './par-type-kafalat';
import ParTypeKafalatDetail from './par-type-kafalat-detail';
import ParTypeKafalatUpdate from './par-type-kafalat-update';
import ParTypeKafalatDeleteDialog from './par-type-kafalat-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ParTypeKafalatUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ParTypeKafalatUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ParTypeKafalatDetail} />
      <ErrorBoundaryRoute path={match.url} component={ParTypeKafalat} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ParTypeKafalatDeleteDialog} />
  </>
);

export default Routes;
