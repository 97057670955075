import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './met-income-source.reducer';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const MetIncomeSourceDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, []);

  const metIncomeSourceEntity = useAppSelector(state => state.metIncomeSource.entity);
  return (
    <Row>
      <Col md="8">
        <h2 data-cy="metIncomeSourceDetailsHeading">
          <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.detail.title">MetIncomeSource</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.code">Code</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.name">Name</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.name}</dd>
          <dt>
            <span id="nameAr">
              <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.nameAr">nameAr</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.nameAr}</dd>
          <dt>
            <span id="nameEn">
              <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.nameEn">nameEn</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.nameEn}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="almobadaraMasterDataApp.metIncomeSource.status">Status</Translate>
            </span>
          </dt>
          <dd>{metIncomeSourceEntity.status}</dd>
        </dl>
        <Button tag={Link} to="/met-income-source" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/met-income-source/${metIncomeSourceEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default MetIncomeSourceDetail;
