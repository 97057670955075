import React, { useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getEntity } from './par-type-kafalat.reducer'; // Assurez-vous que le chemin est correct
import { useAppDispatch, useAppSelector } from 'app/config/store';

export const ParTypeKafalatDetail = (props: RouteComponentProps<{ id: string }>) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEntity(props.match.params.id));
  }, [dispatch, props.match.params.id]);

  const parTypeKafalatEntity = useAppSelector(state => state.parTypeKafalat.entity);

  return (
    <Row>
      <Col md="8">
        <h2 data-cy="parTypeKafalatDetailsHeading">
          <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.detail.title">ParTypeKafalat</Translate>
        </h2>
        <dl className="jh-entity-details">
          <dt>
            <span id="id">
              <Translate contentKey="global.field.id">ID</Translate>
            </span>
          </dt>
          <dd>{parTypeKafalatEntity.id}</dd>
          <dt>
            <span id="code">
              <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.code">Code</Translate>
            </span>
          </dt>
          <dd>{parTypeKafalatEntity.code}</dd>
          <dt>
            <span id="name">
              <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.name">Name</Translate>
            </span>
          </dt>
          <dd>{parTypeKafalatEntity.name}</dd>
          <dt>
            <span id="nameAr">
              <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.nameAr">NameAr</Translate>
            </span>
          </dt>
          <dd>{parTypeKafalatEntity.nameAr}</dd>
          <dt>
            <span id="nameEn">
              <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.nameEn">nameEn</Translate>
            </span>
          </dt>
          <dd>{parTypeKafalatEntity.nameEn}</dd>
          <dt>
            <span id="status">
              <Translate contentKey="almobadaraMasterDataApp.parTypeKafalat.status">Status</Translate>
            </span>
          </dt>
          <dd>
            <Translate contentKey={`almobadaraMasterDataApp.Status.${parTypeKafalatEntity.status}`} />
          </dd>
        </dl>
        <Button tag={Link} to="/par-type-kafalat" replace color="info" data-cy="entityDetailsBackButton">
          <FontAwesomeIcon icon="arrow-left" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.back">Back</Translate>
          </span>
        </Button>
        &nbsp;
        <Button tag={Link} to={`/par-type-kafalat/${parTypeKafalatEntity.id}/edit`} replace color="primary">
          <FontAwesomeIcon icon="pencil-alt" />{' '}
          <span className="d-none d-md-inline">
            <Translate contentKey="entity.action.edit">Edit</Translate>
          </span>
        </Button>
      </Col>
    </Row>
  );
};

export default ParTypeKafalatDetail;
