import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import ParCountry from './par-country';
import ParCountryDetail from './par-country-detail';
import ParCountryUpdate from './par-country-update';
import ParCountryDeleteDialog from './par-country-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={ParCountryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={ParCountryUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id`} component={ParCountryDetail} />
      <ErrorBoundaryRoute path={match.url} component={ParCountry} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={ParCountryDeleteDialog} />
  </>
);

export default Routes;
